import React from 'react';

import { loadCSS } from '../../../../utils/loadCSS';
import '../../../../assets/styles/wallpaper.css';

loadCSS('wallpaper-site');

export const WallpaperSiteWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <>{children}</>;
};
export default WallpaperSiteWrapper;
